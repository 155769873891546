//import "core-js/stable";
//import "regenerator-runtime/runtime";
import $ from 'jquery';
import 'jquery.scrollbar';

window.$ = $;
window.jQuery = $;

import'./mfp.js';
//import'./perfect-scrollbar.js';
import'./modernizr.js';
import './main.js';
import {animateGrid} from './animate-grids.js'
import {initSearch} from './search.js'
import PerfectScrollbar from 'perfect-scrollbar';

const ym = window.ym || ym;

$(document).ready(function(){
    animateGrid('.animated-grid');
    initSearch($('#menu-search-box'),'menu');
    initSearch($('#page-search-box'),'page');
    initSearch($('#site-search-box'),'site');

    new PerfectScrollbar('[data-scrollbar]', {});

    document.addEventListener('inkyform', function(e){
        const type = e.detail.type;
        ym(55257646, 'reachGoal', type);
    })
})
