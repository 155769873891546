import jQuery from 'jquery';
import BeerSlider from './BeerSlider.js';

(function( $ ) {
    $.fn.floatAnimation = function(length) {
        $(this).hover(function(){$(this).find('svg').addClass('animated');});
    };

    $.fn.BeerSlider = function ( options ) {
        options = options || {};
        return this.each(function() {
            new BeerSlider(this, options);
        });
    };

    $('.beer-slider').each(function( index, el ) {
        $(el).BeerSlider({start: $(el).data('beer-start') || 50});
    });
}( jQuery ));



jQuery( function ( $ ) {
    'use strict';

    $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
        event.preventDefault();
        event.stopPropagation();
        $(this).parent().siblings().removeClass('open');
        $(this).parent().toggleClass('open');
    });

    // Add Option to add Fullwidth Section
    function fullWidthSection(){
        var screenWidth = $(window).width();
        if ($('.entry-content').length) {
            var leftoffset = $('.entry-content').offset().left;
        }else{
            var leftoffset = 0;
        }
        $('.full-bleed-section').css({
            'position': 'relative',
            'left': '-'+leftoffset+'px',
            'box-sizing': 'border-box',
            'width': screenWidth,
        });
    }
    fullWidthSection();
    $( window ).resize(function() {
        fullWidthSection();
    });

    // Allow smooth scroll
    $('.page-scroller').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').animate({
            'scrollTop': $target.offset().top
        }, 1000, 'swing');
    });

    $(document).ready(function(){
        var $webp = $('html').hasClass('webp');

        $("img[data-src]").each(function(){
            var $img = $(this);
            if($webp && $img.data('webp'))
                $img.attr('src', $img.data('webp'));
            else
                $img.attr('src', $img.data('src'));

        });
        $("[data-loadbg]").each(function(){
            var $img = $(this);
            var $bg = $img.data('loadbg');

            if($bg)
            {
                $img.css('background-image', 'url('+$bg+')');
            }
        });


        $("#form-activator").magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',

            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function() {
                    if($(window).width() < 700) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });

        $('.toggle-label').on('click', function(){
            var $el = $(this);
            $el.parent().toggleClass('open');
        })

        $('.icon-block').each(function(){
            $(this).floatAnimation(8000);
        })

        $('.search-trigger').on('click', function(){
            $('.search-container').toggleClass('shown');
        });

        $('#blackout').on('click', function(){
            $('.search-container').removeClass('shown');
        })

        $('.mobile-menu-icon').on('click', function(){
            let $elem = $(this);
            let target = $elem.data('target');

            if(target.startsWith('#')){
                $(target).toggleClass('shown');
                $elem.toggleClass('active');
            }
            else{
                if(target.startsWith('http')){
                    window.open(target, '_blank');
                }
                else {
                    window.location.href = target;
                }
            }
        })

        $('.hidespan').on('click', function(e){
            e.preventDefault();
            let elem = $(this);
            let text = elem.data('printers');
            elem.replaceWith(text);
        })
    });
});