import $ from 'jquery';
class Search {
    constructor(element, type) {
        this.$el = $(element);
        console.log('init', this.$el);
        this.abortController = null;
        this.type = type;
        this.init();
    }

    init = () => {
        let overlay;
        if(this.type === 'menu'){
            overlay = $('#site-search-overlay');
        }
        else if(this.type === 'site'){
            overlay = null;
        }
        else{
            overlay = $('<div class="search-overlay">');
            this.$el.before(overlay);
        }

        this.$ovr = overlay;

        let results;

        if(this.type === 'site'){
            results = $('#site-search-results');
        }
        else{
            results = $('<div class="search-results">');
            this.$el.after(results);
        }

        this.$res = results;

        if(this.type === 'menu'){
            this.$res.css('opacity', 0);
            this.menuResize();
            window.addEventListener('resize', this.menuResize);
        }

        this.$el.on('input', this.search);
        this.$el.on('keydown', (key) => {
            if(key.keyCode === 27)
            {
                this.$el.val('');
                this.$el.blur();
                this.hideBox();
                this.abortController?.abort();
            }
        });
        //this.$el.on('change', this.search);
        this.$el.on('click',  (e) => {
            e.stopPropagation();
            if(this.$el.val()?.length){
                this.showBox();
            }
        });
        /*this.$el.on('blur', (e) => {
            this.hideBox();
        })*/

        this.$res?.on('click', (e) => {e.stopPropagation();});
        this.$ovr?.on('click', (e) => {e.stopPropagation(); this.hideBox();});
    }

    menuResize = () => {
        if(this.type === 'page') {
            this.$res.css('left',0);
            this.$res.css('top',this.$el.outerHeight());
            this.$res.css('width',this.$el.outerWidth());
        }
        else if(this.type ==='menu') {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const elementHeight = this.$res.outerHeight();
            const parent = $('#menu-container');
            const width = parent.outerWidth();
            const elwidth = windowWidth - width - (windowWidth<=1140?35:96);

            const boxTop = this.$el.parent().position().top;
            const boxHeight = this.$el.outerHeight();

            this.$res.css('left', width+'px');
            this.$res.css('position','fixed');
            let topPosition = (windowHeight - elementHeight)/2;
            if(elementHeight < 120){
                topPosition = (boxTop + boxHeight/2)-elementHeight/2;
            }
            if(topPosition < 0){ topPosition = 0;}

            this.$res.css('top', topPosition + 'px');
            this.$res.css('width', elwidth + 'px');
        }

    }

    showBox = () => {
        if(this.type==='menu'){
            this.$res.fadeIn(100);
            this.menuResize();
            this.$res.animate({opacity: 1}, 100);
            this.$ovr?.fadeIn(200);
        }
        else {
            this.$res.fadeIn(100);
            this.menuResize();
            this.$ovr?.fadeIn(100);
        }
    }

    hideBox = () => {
        if(this.type === 'menu'){
            this.$res.animate({opacity: 0}, 200);
            this.$res.fadeOut(200);
            this.$ovr?.fadeOut(200);
        }
        else {
            this.$res.fadeOut(200);
            this.$ovr?.fadeOut(200);
        }
    }

    showResult = (html) => {
        this.$res.html(html);
        //this.menuResize();
        this.showBox();
    }

    search = async() => {
        if(this.abortController) this.abortController.abort();

        let val = this.$el.val();
        if(val?.length < 3){
            this.showResult('<p>Введите более трёх символов для поиска.</p>');
            return;
        }
        else{
            this.showResult('<p>Подождите, идёт поиск...</p>');
        }

        const encodedParam = encodeURIComponent(val);
        const url = `/api/search/?search=${encodedParam}`;
        this.abortController = new AbortController();

        try{
            const response = await fetch(url, {signal: this.abortController.signal})
            const {cartridges, printers} = await response.json();
            let html = '';

            if(!cartridges.length &&!printers.length) {
                this.showResult('<p>Ничего не найдено. Попробуйте изменить запрос.</p>');
                return;
            }

            if(cartridges.length > 0){
                let cart = cartridges;
                cart = cart.slice(0,30);

                html += '<h3>Расходные материалы и запчасти</h3>';
                html+='<ul>';
                html+= cart.map((item) => {
                    return `<li><a href="/product/${item.slug}">${item.type} ${item.partnum}</a></li>`;
                }).join('');
                html+='</ul>';

                if(cartridges.length > 30)
                    html+='<p class="too-many-notice"> Найдено слишком много результатов. Показаны только 30. </p>';
            }

            if(printers.length > 0)
            {
                let prn = printers;
                prn = prn.slice(0,15);

                html += '<h3>Принтеры</h3>';
                html += '<ul>';
                html+= prn.map((item) => {
                    return `<li><a href="/catalog/${item.vslug}/${item.pslug}">${item.text}</a></li>`;
                }).join('');
                html+='</ul>';
                if(printers.length > 15)
                {
                    html+='<p class="too-many-notice">Поисковый запрос дал слишком много результатов. Попробуйте уточнить условия поиска.</p>';
                }
            }

            this.showResult(html);

        }
        catch(e){
            console.log(e);
        }
    }
}

export const initSearch = function(element, type) {
    new Search(element, type);
}

export default Search;