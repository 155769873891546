import $ from 'jquery';
import module from './animate-css-grid.js';
window.test = module.wrapGrid;

function throttle(callback, limit) {
    let wait = false; // Initially, we're not waiting
    return function() {
        // We return a throttled function
        if (!wait) {
            // If we're not waiting
            callback.call(); // Execute users function
            wait = true; // Prevent future invocations
            setTimeout(function() {
                // After a period of time
                wait = false; // And allow future invocations
            }, limit);
        }
    };
}

export const animateGrid = (selector) => {
    const grids = document.querySelectorAll(selector).values();
    for (const grid of grids) {
        const { forceGridAnimation } = module.wrapGrid(grid, {duration: 500});
        window.addEventListener("resize", throttle(forceGridAnimation, 50));
    }
}